<template lang="pug">
include ../../../../configs/template.pug
b-card(header-tag="header")
  template(v-if="isEditingForm" slot="header")
    div.flex-row-sb
      div.text-uppercase.text-left {{ $t('crewingCompanyEditing') }}
        span.ml-1 (ID: {{ company.id }})
      div.documentIconControl
        v-icon(@click="$router.push({ path: `/back-office/crewing/group/${$route.params.groupID}/company` })").close mdi-close

  v-progress-linear(v-if="isCardLoading" indeterminate)
  div.text-left
    div.w-100.mt-3
      +field-validation('company.name_ukr', 'crewingCompanyName', '["required", "alphaUA"]')
    div.w-100.mt-3
      +field-validation('company.name_eng', 'nameEn', '["alphaEN"]')
    div(v-if="isAddingForm").w-100.mt-3
      +select-validation('company.director', 'listCrewingHeads', 'groupHead', '"crewingHeadFullName"', '["required"]')(
        :loading="isCrewingHeadsLoading")
    div(v-else).w-100.mt-3
      +select-validation('company.group', 'crewingGroupsList', 'group', '"name"', '["required"]')
    div.w-100.mt-3
      div {{ typeCompany }}
      +select-validation('company.type_company', 'institutionTypesList', 'typeCompany', 'labelName', '["required"]')
    div.w-100
      +checkbox('company.is_for_isc', 'isForISC')(
        :disabled="!company.name_eng"
      ).mt-2
    div.w-100.d-flex.justify-center.mt-3
      v-btn(@click="checkFieldsValidation" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { backOfficeCrewingCompany } from '@/mixins/validationRules'
import { mapState, mapActions } from 'vuex'
import { checkFieldsWhichChanged } from '@/mixins/main'

export default {
  data () {
    return {
      company: {
        name_ukr: null,
        name_eng: null,
        director: null,
        type_company: null,
        group: Number(this.$route.params.groupID),
        is_for_isc: false
      },
      prevInfoCompany: {},
      companyID: this.$route.params.companyID,
      listCrewingHeads: [],
      isCardLoading: false,
      isLoading: false,
      isCrewingHeadsLoading: false
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      institutionTypesList: state => state.directory.institutionTypes,
      crewingGroupsList: state => state.directory.crewingGroups
    }),
    isAddingForm () { return this.$route.name === 'crewing-companies-backoffice' },
    isEditingForm () { return this.$route.name === 'crewing-companies-backoffice-editing' }
  },
  watch: {
    'company.name_eng' (val) {
      !val && (this.company.is_for_isc = false)
    }
  },
  beforeMount () {
    if (this.isEditingForm) this.getCrewingCompanyInformation()
    else this.getCrewingHeads()
  },
  validations () { return backOfficeCrewingCompany(this) },
  methods: {
    ...mapActions(['setCrewingCompany', 'getCrewingCompaniesByID', 'updateCrewingCompany', 'getCrewingCompanyManagers']),

    getCrewingCompanyInformation () {
      this.isCardLoading = true
      this.getCrewingCompaniesByID(this.companyID).then(response => {
        if (response?.code === 200) {
          this.prevInfoCompany = { ...response.data }
          this.company = response.data
        }
      }).finally(() => { this.isCardLoading = false })
    },

    getCrewingHeads () {
      const params = new URLSearchParams({ type_user: 'crewing_head', is_employed: 'false' })

      this.isCrewingHeadsLoading = true
      this.getCrewingCompanyManagers(params).then(response => {
        if (response?.code === 200) this.listCrewingHeads = response.data.results
      }).finally(() => { this.isCrewingHeadsLoading = false })
    },

    checkFieldsValidation () {
      if (this.$v.$invalid) return this.$v.$touch()

      if (this.isAddingForm) this.addCrewingCompany()
      else this.updateCrewingCompanyInfo()
    },

    addCrewingCompany () {
      const body = this.company
      body.is_red = !!this.company.type_company

      this.isLoading = true
      this.setCrewingCompany(body).then(response => {
        if (response?.code === 201) {
          this.$notification.success('addedCrewingCompany')
          this.$emit('getCrewingCompanies')
          this.$parent.viewAdd = false
          this.$v.$reset()
        }
      }).finally(() => { this.isLoading = false })
    },

    updateCrewingCompanyInfo () {
      let body = { ...this.company }
      body = { ...checkFieldsWhichChanged(body, this.prevInfoCompany) }
      this.isLoading = true
      if (Object.keys(body).length) {
        this.updateCrewingCompany({ id: this.companyID, body }).then(response => {
          if (response?.code === 200) {
            this.$notification.success('editedCrewingCompany')
            this.$router.push({ name: 'crewing-companies-backoffice', params: { groupID: response.data.group } })
          }
        }).finally(() => { this.isLoading = false })
      } else {
        this.$notification.info('notify.info.information didnt changed')
        this.isLoading = false
      }
    }
  }
}
</script>
